<template lang="pug">
div
  div
    v-btn(v-if="!edit" text color="primary" @click="$router.go(-1)")
      v-icon(left) keyboard_backspace
      | Back
    v-btn(v-if="edit" text color="blue" @click="edit = false")
      v-icon(left) keyboard_backspace
      | Back
    v-card(v-if="!edit")
      v-card-title(primary-title)
        h3.headline.mb-0
          v-icon(v-if="unit.status === 'Posted'") check_circle
          | &nbsp; {{unit.stock}} - {{unit.vin}}
        v-spacer
        p {{unit.category}}
      v-card-text
        h3 Pricing:
        ul
          li Cost: {{unit.k}}
          li Price ${{unit.price}}
          li MSRP: ${{unit.msrp}}
        br
        h3 Model:
        v-chip(v-if="make" label) {{unit.make}}
        v-chip(label v-if="unit.width && unit.width != 0")
          span {{unit.width}}x
          span {{unit.length}}
        v-chip(v-if="unit.color" label :color="unit.color") {{unit.color}}
        v-chip(v-if="model") {{unit.model}}
        br
        h3 Features:
        v-chip(v-if="!(unit.features).includes(',')" label outlined v-for="feature in unit.features" :key="feature") {{feature}}
        br
        h3 Status:
        v-chip {{ unit.status }}
        v-chip(v-if="unit.location") {{ unit.location }}
      v-card-actions
        v-btn(text color="orange" @click="edit = true") Edit
        v-btn(text color="orange" @click="dupdialog = true") Duplicate
        v-btn(text color="blue" v-if="unit.status === 'Posted'" :href="unit.website" target="_blank") View
        v-btn(text color="red" @click="deleteUnit(unit)") Remove
    v-card(v-if="edit")
      v-card-title
        form
          v-text-field(v-model="unit.stock" label="Stock" type="text")
          v-text-field(v-model="unit.vin" label="Vin" type="number")
          v-text-field(v-model="unit.k" label="Cost" type="number")
          v-text-field(v-model="unit.msrp" label="MSRP" type="number")
          v-text-field(v-model="unit.price" label="Price" type="number")
          v-autocomplete(v-model="unit.category" :items="categories" auto-select-first item-value="name" item-text="name" label="Category")
          v-autocomplete(v-model="unit.make" :items="makes" auto-select-first item-value="name" item-text="name" label="Make")
          v-autocomplete(v-model="unit.model" :items="modelsbymake" auto-select-first item-value="name" item-text="name" label="Model")
          v-text-field(v-model="unit.width" label="Width" type="number")
          v-text-field(v-model="unit.length" label="Length" type="number")
          v-autocomplete(v-model="unit.color" :items="colors" label="Colors")
          v-autocomplete(v-model="unit.features" :items="features" hide-selected label="Features" multiple item-text="name" item-value="name")
          v-autocomplete(v-model="unit.status" :items="status" label="Status")
          v-text-field(v-if="unit.status === 'Posted'" v-model="unit.website" label="Website" type="text")
      v-card-actions
        v-btn(color="primary" @click="saveEdit()") Save Edit
    v-snackbar(v-model="snackbarsaved" right top) Edits Saved
      v-btn(color="orange" text @click="snackbarsaved = false") Close
    v-snackbar(v-model="snackbardup" right top) Unit Duplicated
      v-btn(color="orange" text @click="snackbardup = false") Close
  v-dialog(v-model="dupdialog" persistent max-width="500")
    v-card
      v-card-actions
        v-card-title
          span.text-h5 Duplicate {{ unit.stock }}
        v-spacer
        v-btn(color="red darken-1" text @click="closeDup()") Close
      v-card-text
        v-form(v-model="dupIsValid")
          v-row
            v-col(cols="12" sm="6" md="6")
              v-text-field(
                v-model="dup.stock"
                label="Stock #"
                required
                :rules="[v => !!v || 'New Stock # is required']"
              )
            v-col(cols="12" sm="6" md="6")
              v-text-field(
                v-model="dup.vin"
                label="Last 4 VIN"
                type="number"
                required
                :rules="[v => !!v || 'Last 4 of new VIN is required']"
              )
      v-card-actions
        v-spacer
        v-btn(color="green darken-1" text :disabled="!dupIsValid" @click="duplicateUnit(unit)") Duplicate
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      snackbar: false,
      snackbarsaved: false,
      snackbarmodelcopy: false,
      snackbardup: false,
      edit: false,
      dupdialog: false,
      dupIsValid: true,
      dup: {
        stock: '',
        vin: ''
      }
    }
  },
  methods: {
    deleteUnit (unit) {
      const lastchance = confirm('Are you sure you want to delete this trailer?')
      if (lastchance) {
        this.$store.dispatch('deleteUnit', unit.id)
        this.$router.go(-1)
      }
    },
    duplicateUnit (unit) {
      this.dupdialog = false
      const dupUnit = {
        stock: this.dup.stock,
        vin: this.dup.vin,
        k: this.unit.k || 0,
        msrp: this.unit.msrp || 0,
        price: this.unit.price || 0,
        category: this.unit.category || '',
        make: this.unit.make || '',
        model: this.unit.model || '',
        width: this.unit.width || 0,
        length: this.unit.length || 0,
        color: this.unit.color || '',
        features: this.featuresOrNah || [],
        status: 'Entered',
        website: '',
        id: ''
      }
      this.$store.dispatch('addUnit', dupUnit)
      this.$store.commit('updateSearch', this.dup.stock)
      this.snackbardup = true
      this.$router.go(-1)
    },
    saveEdit () {
      const newUnit = {
        stock: this.unit.stock || '',
        vin: this.unit.vin || 0,
        k: this.unit.k || 0,
        msrp: this.unit.msrp || 0,
        price: this.unit.price || 0,
        make: this.unit.make || '',
        model: this.unit.model || '',
        category: this.unit.category || '',
        width: this.unit.width || 0,
        length: this.unit.length || 0,
        color: this.unit.color || '',
        features: this.featuresOrNah || [],
        status: this.unit.status || '',
        website: this.unit.website || '',
        id: this.unit.id
      }
      this.snackbarsaved = true
      this.$store.dispatch('updateUnit', newUnit)
      this.edit = false
    },
    closeDup () {
      this.dupdialog = false
      this.dup.stock = ''
      this.dup.vin = ''
    }
  },
  computed: {
    featuresOrNah () {
      if (this.unit.features) {
        const featurearray = [...this.unit.features]
        return featurearray
      } else {
        return null
      }
    },
    ...mapGetters([
      'inventory',
      'makes',
      'models',
      'colors',
      'status',
      'categories',
      'features'
    ]),
    unit () {
      return this.$store.getters.getUnitById(this.$route.params.id)
    },
    model () {
      return this.$store.getters.getModelByName(this.unit.model)
    },
    make () {
      return this.$store.getters.getMakeByName(this.unit.make)
    },
    multiple () {
      return (100 - this.unit.percentage) / 100
    },
    fiveIfy () {
      return Math.ceil((this.unit.k / this.multiple) / 5) * 5
    },
    modelsbymake () {
      return this.$store.getters.getModelsByMake(this.unit.make)
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
